import React, { useState, useEffect, useMemo } from 'react'
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import Header from './Main/Header.js'
import Submenu from './Main/Submenu.js'
import Message from './Main/Message.js'
import Footer from './Main/Footer.js'
import API from '../api.js'
import config from '../config.js'

export default props => {

    const [meta, setMeta] = useState({
        title: '', router: '', container: true,
        maxWidth: '960px', submenu: []
    })
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false)
    const [key, setKey] = useState(localStorage.getItem('key'))
    const navigate = useNavigate()

    const setAlert = message => {
        setMessage({
            type: 'alert', title: 'Повідомлення', body: message
        })
    }

    const setConfirm = (message) => {
        return new Promise((resolve, reject) => {
            setMessage({
                type: 'confirm',
                title: 'Підтвердження',
                body: message, 
                onTrue: () => resolve(true),
                onFalse: () => resolve(false)
            })
        });
    }

    const api = useMemo(() => ({ 
        main: API.createMain(setLoading, setAlert, key),
        panel: API.createPanel(setLoading, setAlert, navigate), 
        image: API.createImage(setLoading, setAlert, key)
    }), [])

    useEffect(async () => {
        if (window.location.pathname === '/') {
            setMeta({
                title: 'Головна', router: 'main', container: true,
                maxWidth: '960px', submenu: []
            })
        }
    }, [window.location.pathname])

    useEffect(async () => {
        const link = document.createElement('link')
        link.setAttribute('href', config.main + '/article.css?v=3')
        link.setAttribute('rel', 'stylesheet')
        document.head.appendChild(link)
    }, [])

    return <React.StrictMode>
        <Message {...message} setMessage={setMessage} />
        {loading && (
            <div className="spinner-border position-fixed bottom-50 start-50"
                role="status" style={{ zIndex: 9999 }}>
                <span className="visually-hidden">Loading...</span>
            </div>
        )}
        {props.template ?
            key ? (<>
                <Header />
                <main>
                    <div id="header" className="my-5 px-5 d-flex">
                        <h1 className="me-auto">{meta.title}</h1>
                        <ul className="nav">
                            {meta?.submenu &&
                                <Submenu items={meta.submenu} setConfirm={setConfirm} />
                            }
                        </ul>
                    </div>
                    <div id="body" className="container"
                        style={{ maxWidth: meta.maxWidth }}>
                        <Outlet context={{
                            setMeta, setAlert, setConfirm, setMessage, api
                        }} />
                    </div>
                </main>
                <Footer />
            </>) : <Navigate to={config.login} replace />
            : <Outlet context={{ setAlert, setKey, api }} />
        }
    </React.StrictMode>
}