import React from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { Form, FloatingLabel, Button } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import MD5 from 'crypto-js/md5.js'
import config from '../../config.js'

export default () => {

    const context = useOutletContext()
    const navigate = useNavigate()

    const handleSubmit = async event => {
        event.preventDefault()
        const form = new FormData(event.target)
        if (!form.get('g-recaptcha-response')) {
            return context.setAlert('Підтвердіть що ви не робот')
        }
        const hash = MD5(form.get('password')).toString()
        await context.api.panel.post('/доступ/вхід', {
            hash, recaptcha: form.get('g-recaptcha-response')
        })
        context.setKey(hash)
        localStorage.setItem('key', hash)
        navigate('/публікації')
    }

    return (
        <main className="vh-100 d-flex">
            <div className="text-center m-auto">
                <Form className="border p-3" onSubmit={handleSubmit}>
                    <h2 className="mt-2 mb-5">Авторизація</h2>
                    <FloatingLabel label="Пароль">
                        <Form.Control type="password" name="password"
                            autoComplete="false" required />
                    </FloatingLabel>
                    <div className="my-3">
                        <ReCAPTCHA sitekey={config.recaptcha.key} />
                    </div>
                    <Button variant="primary" type="submit"
                        size="lg" className="mt-3">
                        Авторизуватись
                    </Button>
                </Form>
            </div>
       </main>
    )
}