import React, { useState, useEffect, useMemo, useReducer } from 'react'
import { useOutletContext } from 'react-router-dom'
import Form, { Table, Field, Row, Cell } from '../../components/Form.js'
import Longread, { Reducer, actions} from '../../components/Editor.js'
import Parent from './Editor/Parent.js'
import Type from './Editor/Type.js'
import URL from './Editor/URL.js'
import config from '../../config.js'

export default props => {

    const [post, setPost] = useState({
        parent: '61927311f00b7861db3097b5',
        type: '61927310f00b7861db3095e5',
        popularity: 0,
        date: new Date().toISOString().split('T')[0],
        status: false
    })
    const [state, dispatch] = useReducer(Reducer, [{ 
        id: 0, type: 'main', title: 'Найменування'
    }])
    const [slug, setSlug] = useState()
    const context = useOutletContext()

    const handleSubmit = async () => {
        const postNew = {
            ...post, title: state[0].title
        }
        if (state[0]?.image) {
            postNew.image = state[0].image
        } else {
            delete postNew.image
        }
        if (state.length > 1) {
            postNew.blocks = state.slice(1)
        } else {
            delete postNew.blocks
        }
        console.log(postNew)
        setPost(postNew)
        if (props?.id) {
            await context.api.panel.put('/публікації/' + props.id, postNew)
            context.api.main.delete('/кеш/' + slug)
        } else {
            await context.api.panel.post('/публікації', postNew)
        }
        props.onSubmit()
    }

    const handleDelete = async () => {
        await context.api.panel.delete('/публікації/' + props.id)
        props.onSubmit()
    }

    useEffect(async () => {
        if (!props?.id) return
        const postNew = await context.api.panel.get('/публікації/' + props.id)
        const blocks = [{ id: 0, type: 'main', title: postNew?.title }]
        if (postNew?.image) {
            blocks[0].image = postNew?.image
        }
        if (postNew?.blocks) {
            blocks.push(...postNew?.blocks)
        }
        setPost(postOld => ({ ...postOld, ...postNew }))
        dispatch(actions.load(blocks))
        setSlug(postNew.slug)
        console.log(postNew)
    }, [])

    const url = useMemo(() => (
        config.main + '/' + post.slug
    ), [post.slug])

    return <Form data={post} show={props.show} onHide={props.onHide}
        onChange={setPost} onSubmit={handleSubmit} onDelete={handleDelete}
        title="Редагування публікації" fullscreen={true}>
        <Longread blocks={{ state, dispatch, actions }} />
        <Table size="medium">
            <Row>
                <Field.Description placeholder="Опис публікації ..." />
            </Row>
            <Row>
                <Cell sm={6}><Parent /></Cell>
                <Cell sm={6}><Type /></Cell>
            </Row>
            <Row>
                <Cell sm={3}>
                    <Field.Latitude name="location.center.latitude"
                        label="Широта центру" />
                </Cell>
                <Cell sm={3}>
                    <Field.Longitude name="location.center.longitude"
                        label="Довгота центру" />
                </Cell>
                <Cell sm={3}>
                    <Field type="number" name="popularity"
                        min="0" max="100" step="1" placeholder="0" label="Популярність"
                        className="text-end" title="Ціле число від 0 до 100" />
                </Cell>
                <Cell sm={3}>
                    <Field.Date label="Дата публікації" />
                </Cell>
            </Row>
            <Row>
                <Cell sm={3}>
                    <Field.Latitude name="location.bounds.north"
                        label="Північна межа" />
                </Cell>
                <Cell sm={3}>
                    <Field.Longitude name="location.bounds.east"
                        label="Східна межа" />
                </Cell>
                <Cell sm={3}>
                    <Field.Latitude name="location.bounds.south"
                        label="Південна межа" />
                </Cell>
                <Cell sm={3}>
                    <Field.Longitude name="location.bounds.west"
                        label="Західна межа" />
                </Cell>
            </Row>
            <Row>
                <Cell sm={6}>
                    <Field.Slug source={state[0].title} required
                        placeholder="посилання-на-сторінку" />
                </Cell>
                <Cell sm={6}><URL /></Cell>
            </Row>
            <Row>
                <Cell sm={6}><Field.Status title="Видимість публікації" /></Cell>
            </Row>
            <Row>
                <Field label="Адреса публікації">
                    <a href={url + '?перегляд'} className='d-block'>{url}</a>
                </Field>
            </Row>
       </Table>
    </Form>
}