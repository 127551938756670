const main = 'https://мандри.укр'

export default {
    name: 'Мандри',
    api: {
        main: { url: main, timeout: 10_000 },
        panel: { url: '/ппі', timeout: 10_000 },
        image: { url: 'https://фото.мандри.укр/ппі', timeout: 20_000 }
    },
    image: {
        host: 'https://фото.мандри.укр',
        widths: [320, 480, 640, 800, 960, 1280, 1600, 1920, 2560, 3840],
        maxSize: 16 * 1024 ** 2
    },
    login: encodeURI('/доступ/вхід'),
    main,
    recaptcha: {
        key: '6LeydhgUAAAAAOR1sqQ-HadqBnsZdf_mMyAAOefW'
    },
    tinymce: '/tinymce/tinymce.min.js',
    highlight: {
        languages: {
            count: 5,
            list: {
                txt: 'Text', xml: 'XML', css: 'CSS', html: 'HTML', json: 'JSON',
                yaml: 'YAML', cpp: 'C++', java: 'Java', python: 'Python',
                javascript: 'JavaScript', typescript: 'TypeScript',
                perl: 'Perl', php: 'PHP'
            }
        }
    },
    copyright: '2024',
    limit: 100
}
