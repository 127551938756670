import React, { useContext } from 'react'
import Context from '../../../contexts/Form.js'
import Field from './Field.js'

export default props => {

    const name = props.name ?? 'slug'
    const minLength = props?.minLength ?? 3
    const maxLength = props?.maxLength ?? 128
    const data = useContext(Context)

    const handleChange = event => {
        if (!props?.source || event.target.value) return
        data.set(name, props.source.replace(/['’`]+/g, '')
        .replace(/\s/g, '-').toLowerCase())
    }

    return <Field type="text" name={name}
        onFocus={handleChange} onBlur={handleChange} label="Посилання"
        pattern="[а-яіїєґ\-]+" minLength={minLength} maxLength={maxLength}
        title={`Від ${minLength} до ${maxLength} прописних букв, дефісів`}
        placeholder="відносне-посилання"
        {...props} />
}
