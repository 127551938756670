import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from '../../../components/Form.js'

export default () => {

    const [parents, setParents] = useState([])
    const context = useOutletContext()

    useEffect(async () => {
        const groups = {};
        (await context.api.panel.get(`/публікації/предки`))
        .forEach(item => {
            const parent_id = item?.parent_id ?? 0
            if (typeof groups[parent_id] === 'undefined') {
                groups[parent_id] = []
            }
            groups[parent_id].push({ ...item })
        })
        const tree = []
        createTree(groups[0])
        setParents(tree)
        function createTree(nodes, level = -1) {
            level ++
            for (const node of nodes) {
                tree.push({...node, level })
                if (typeof groups[node._id] !== 'undefined') {
                    createTree(groups[node._id], level)
                }
            }
            return nodes
        }
    }, [])

    return <Field type="select" name="parent" label="Предок публікації">
        {parents.map((parent, index) => 
            <option value={parent._id} key={index} dangerouslySetInnerHTML={
                { __html: '&nbsp;&nbsp;'.repeat(parent.level) + parent.title }
            }>
            </option>
        )}
    </Field>
}