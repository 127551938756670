import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import Moment from 'moment'
import Table, { Row, Cell } from '../components/Table.js'
import Filter from './Post/Filter.js'
import Editor from './Post/Editor.js'

export default () => {

    const [id, setID] = useState()
    const [posts, setPosts] = useState([])
    const [params, setParams] = useState({
        dateStart: Moment().add(-10, 'years').format('YYYY-MM-DD'),
        dateEnd: Moment().format('YYYY-MM-DD'),
        parent: 'Західна Україна', _sortField: 'title',
        typeExists: true, _limit: 100
    })
    const [editor, setEditor] = useState(false)
    const [filter, setFilter] = useState(false)
    const context = useOutletContext()

    const handleLoad = async () => {
        setPosts(
            await context.api.panel.get('/публікації', { params })
        )
    }

    useEffect(async () => {
        context.setMeta({ 
            title: 'Публікації',
            submenu: [
                { title: 'Створити', onClick: () => setEditor(true) },
                { title: 'Фільтр', onClick: () => setFilter(true) }
            ]
        })
        handleLoad()
    }, [])

    return <>
        <Table columns={['Назва', 'Батько', 'Тип', 'Популярність', 'Дата']} >
            {posts.map(post => (
                <Row status={post.status} key={post._id}
                    onClick={() => {setID(post._id);setEditor(true);}}>
                    <Cell align="left">{post.title}</Cell>
                    <Cell align="center">{post.parent?.title}</Cell>
                    <Cell align="center">{post.type.title}</Cell>
                    <Cell align="center">{post.popularity}</Cell>
                    <Cell align="center" className="text-nowrap">
                        {post.date.substr(0, 10)}
                    </Cell>
                </Row>
            ))}
        </Table>
        {editor && <Editor id={id} onSubmit={handleLoad}
            show={editor} onHide={() => {setID();setEditor(false)}} />}
        {filter && <Filter data={params} onChange={setParams} onSubmit={handleLoad}
            show={filter} onHide={() => setFilter(false)} />}
    </>
}