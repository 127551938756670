import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import Context from '../../../contexts/Form.js'
import config from '../../../config.js'

export default () => {

    const data = useContext(Context)
    const url = config.main + '/' + data.get('slug')

    return <Form.Group>
        <Form.Label>Адреса</Form.Label>
        <a href={url} className="form-control">{url}</a>
    </Form.Group>
}
