import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default props => {

    const handleClose = () => {
        props.setMessage(null)
    }

    return <Modal show={!!props?.type} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{props?.title ?? 'Повідомлення'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{props.body}</p>
        </Modal.Body>
        <Modal.Footer>
            {props.type === 'alert' ? (
                <Button variant="primary" onClick={handleClose}>Зрозуміло</Button>
            ) : (
                <>
                    <Button variant="secondary" onClick={() => {
                        props?.onFalse && props.onFalse()
                        handleClose()
                    }}>Ні, дякую</Button>
                    <Button variant="primary" onClick={() => {
                        props.onTrue()
                        handleClose()
                    }}>Гаразд</Button>
                </>
            )}
        </Modal.Footer>
    </Modal>
}