import React from 'react'
import { NavLink, Link} from 'react-router-dom'
import config from '../../config.js'
import menu from '../../menu.js'

export default () => {

    return <header>
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
            <div className="container-fluid">
                <Link to="/" title={config.slogan}
                    className="btn btn-outline-success font-weight-bold">
                    {config.name[0]}
                </Link>
                <div className="collapse navbar-collapse ms-3" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-lg-0">
                        {menu.map(item => (
                            <li className="nav-item" key={item.title}>
                                <NavLink to={encodeURI(item.uri)} className="nav-link" >
                                    {item.title}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <NavLink to={encodeURI('/доступ/вихід')} className="nav-link">
                        Вихід
                    </NavLink>
                </div>
            </div>
        </nav>
    </header>
}
