import React from 'react'
import Form, { Field, Row, Cell } from '../../components/Form.js'

export default props => {

    return <Form as="filter" {...props}>
        <Row>
            <Cell sm={4}>
                <Field type="text" name="title" label="Назва"
                    title="Фільтр за заголовком" placeholder="львів" />
            </Cell>
            <Cell sm={4}>
                <Field type="text" name="parent" label="Батко"
                    title="Фільтр за батьком" placeholder="україна" />
            </Cell>
            <Cell sm={4}>
                <Field type="text" name="type" label="Тип"
                    title="Фільтр за типом" placeholder="місто" />
            </Cell>
        </Row>
        <Row>
            <Cell sm={6}>
                <Field type="date" name="dateStart"
                    label="Дата початкова" title="Фільтр за датою початку" />
            </Cell>
            <Cell sm={6}>
                <Field type="date" name="dateEnd"
                    label="Дата кінцева" title="Фільтр за датою кінця" />
            </Cell>
        </Row>
        <Row>
            <Cell sm={6}>
                <Field type="select" name="_sortField"
                    label="Поле сортування">
                    <option value="title">Назва</option>
                    <option value="parent.title">Батько</option>
                    <option value="type.title">Тип</option>
                </Field>
            </Cell>
            <Cell sm={6}>
                <Field type="select" name="_sortOrder"
                    label="Напрям сортування">
                    <option value={1}>Низхідний</option>
                    <option value={-1}>Виcхідний</option>
                </Field>
            </Cell>
        </Row>
        <Row>
            <Cell sm={6}>
                <Field type="select" name="status" label="Статус">
                    <option value="">Всі</option>
                    <option value={true}>Видимі</option>
                    <option value={false}>Приховані</option>
                </Field>
            </Cell>
        </Row>
    </Form>
}