import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Card, Form, FloatingLabel, Button } from 'react-bootstrap';
import config from '../config.js'

export default () => {

    const context = useOutletContext()

    const handleSave = async event => {
        event.preventDefault()
        const formData = new FormData()
        formData.append('title', event.target.title.value)
        formData.append('image', event.target.image.files[0])
        console.log(
            await context.api.image.post('', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                timeout: 10_000
            }
        ))
        context.setAlert(`Зображення збережено`)
    }

    const handleSearch = async event => {
        event.preventDefault()
        const post = (await context.api.panel.get('/публікації',
            { timeout: 5_000, params: {
                image: event.target.image.value,
            }}
        ))[0]
        if (post) {
            console.log(post, config.main + '/' + post.uri)
            context.setAlert('Зображення знайдено')
        } else {
            context.setAlert('Зображення не знайдено')
        }
    }

    const handleCheck = async () => {
        const [links, files] = await Promise.all([
            context.api.panel.get('/зображення', { timeout: 15_000 }),
            context.api.image.get('/список', { timeout: 15_000 })
        ])
        const diff = {
            links: links.filter(link => !files.includes(link)),
            files: files.filter(file => !links.includes(file))
        }
        if (diff.links.length !== diff.files.length) {
            context.setAlert('Виявлено проблеми')
            console.log(diff)
        } else {
            context.setAlert('Проблем не виявлено')
        }
    }

    const handleResize = async () => {
        console.log(
            await context.api.image.patch(
                '/зменшити', null, { timeout: 120_000 }
            )
        )
        context.setAlert(`Розміри змінено`)
    }

    const handleThumbnails = async () => {
        const posts = await context.api.panel.get(
            '/публікації/мініатюри', { timeout: 15_000 }
        )
        console.log(posts)
        await context.api.image.patch('/мініатюри', posts, { 
            timeout: 30_000, headers: { 'Content-Type': 'application/json' }
        })
        context.setAlert('Мініатюри створено')
    }

    const handleRemove = async event => {
        event.preventDefault()
        if (!await context.setConfirm('Видалити зображення?')) return
        await context.api.image.delete(
            '/' + event.target.image.value, { timeout: 3_000 }
        )
        context.setAlert('Зображення видалено')
    }

    useEffect(() => {
        context.setMeta(
            { title: 'Зображення', submenu: [
                {
                    title: 'Перевірити', 
                    description: 'Перевірити цілісність зображень', 
                    onClick: handleCheck,
                    confirm: true
                },
                {
                    title: 'Зменшити', 
                    description: 'Створити зменшені зображення',
                    onClick: handleResize,
                    confirm: true
                },
                {
                    title: 'Мініатюри',
                    description: 'Створити мініатюри зображень',
                    onClick: handleThumbnails,
                    confirm: true
                }
            ]}
        )
    }, [])

    return (
        <div id="body" className="images container text-center" style={{ maxWidth: '768px' }}>
            <Form onSubmit={handleSave}>
                <Card className="upload my-5">
                    <Card.Header>Збереження зображення</Card.Header>
                    <Card.Body className="p-5 pb-4" >
                        <FloatingLabel label="Заголовок зображення">
                            <Form.Control type="text" name="title" pattern="*{3,32}" title="Від 3 до 32 символів" /> 
                        </FloatingLabel>
                        <Form.Control type="file" name="image" className="mt-3" required />
                        <Button type="submit" variant="primary" className="mt-4">Зберегти</Button>
                    </Card.Body>
                </Card>
            </Form>
            <Form onSubmit={handleSearch}>
                <Card className="search my-5">
                    <Card.Header>Пошук зображення в статтях</Card.Header>
                    <Card.Body className="p-5 pb-4">
                        <FloatingLabel label="Хеш зображення">
                            <Form.Control type="text" name="image" pattern="[a-f0-9]{32}"
                                title="Рівно 32 латинські сивола та цифри"
                                required />
                        </FloatingLabel>
                        <Button type="submit" variant="secondary" className="mt-4">Знайти</Button>
                    </Card.Body>
                </Card>
            </Form>
            <Form onSubmit={handleRemove}>
                <Card className="remove my-5">
                    <Card.Header>Видалення зображення</Card.Header>
                    <Card.Body className="p-5 pb-4">
                        <FloatingLabel label="Хеш зображення">
                            <Form.Control type="text" name="image" pattern="[a-f0-9]{32}"
                                title="Рівно 32 латинські сивола та цифри"
                                required />
                        </FloatingLabel>
                        <Button type="submit" variant="danger" className="mt-4">Видалити</Button>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    )
}