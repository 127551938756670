import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import Table, { Row, Cell } from '../components/Table.js'
import Editor from './Page/Editor.js'

export default () => {

    const [id, setID] = useState()
    const [pages, setPages] = useState([])
    const [editor, setEditor] = useState(false)
    const context = useOutletContext()

    const handleLoad = async () => {
        setPages(
            await context.api.panel.get('/публікації', { params: {
                typeExists: false, sortField: 'title', limit: 100
            }})
        )
    }

    useEffect(async () => {
        context.setMeta({
            title: 'Сторінки',
            submenu: [{
                title: 'Створити',
                onClick: () => setEditor(true)
            }]
        })
        handleLoad()
    }, [])

    return <>
        <Table columns={['Назва', 'Посилання', 'Опис']} >
            {pages.map(page => (
                <Row status={page.status} key={page._id}
                    onClick={() => {setID(page._id);setEditor(true)}}>
                    <Cell align="center">{page.title}</Cell>
                    <Cell align="center">{page.slug}</Cell>
                    <Cell align="left">{page?.description}</Cell>
                </Row>
            ))}
        </Table>
        {editor && <Editor id={id} onSubmit={handleLoad}
            show={editor} onHide={() => {setID();setEditor(false)}} />}
    </>
}