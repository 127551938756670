import React, { useEffect } from 'react'
import { useRoutes, useOutletContext } from 'react-router-dom'
import Layout from './layouts/Main.js'
import { Login, Logout } from './pages/Access.js'
import Post from './pages/Post.js'
import Page from './pages/Page.js'
import Tag from './pages/Tag.js'
import Type from './pages/Type.js'
import Image from './pages/Image.js'

const NotFound = () => {

    const context = useOutletContext()

    useEffect(() => {
        context.setMeta({ title: 'Сторінка не знайдена' })
    }, [])

    return (
        <div id="body" className="container" style={{ maxWidth: '768px' }}>
            <p className="lead">
                За вашим запитом нічого не знайдено.
                Скористайтесь меню щоб знайти необхідну вам сторінку.
            </p>
        </div>
    )
}

export default () => {

    return useRoutes([
        { path: 'доступ', element: <Layout template={false} />, children: [
            { path: 'вхід', element: <Login /> },
            { path: 'вихід', element: <Logout /> }
        ] },
        { path: "/", element: <Layout template={true} />, children: [
            { path: "/публікації", element:  <Post />},
            { path: "/сторінки", element: <Page /> },
            { path: "/мітки", element: <Tag /> },
            { path: "/типи", element: <Type /> },
            { path: "/фото", element: <Image /> },
            { path: "*", element: <NotFound /> }
        ] },
    ])
}
