import React, { useEffect } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'

export default () => {

    const context = useOutletContext()
    const navigate = useNavigate()

    useEffect(async () => {
        await context.api.panel.delete('/доступ/вихід')
        localStorage.removeItem('key')
        context.setKey()
        navigate('/доступ/вхід')
    }, [])

    return <></>
}