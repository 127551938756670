import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import Form, { Field, Row } from '../../components/Form.js'

export default props => {

    const [type, setType] = useState({ sort: 1, status: false })
    const context = useOutletContext()

    const handleSubmit = async () => {
        props?.id
            ? await context.api.panel.put('/типи/' + props.id, type)
            : await context.api.panel.post('/типи', type)
        props.onSubmit()
    }

    const handleDelete = async () => {
        await context.api.panel.delete('/типи/' + props.id)
        props.onSubmit()
    }

    useEffect(async () => {
        props?.id && setType(
            await context.api.panel.get('/типи/' + props.id)
        )
    }, [])

    return <Form data={type} show={props.show} onHide={props.onHide}
        onChange={setType} onSubmit={handleSubmit} onDelete={handleDelete}
        title="Редагування типу" size="sm">
        <Row>
            <Field.Title maxLength={32} placeholder="Місто" required />
        </Row>
        <Row>
            <Field.Slug source={type.title} maxLength={32}
                placeholder="посилання-на-тип" required />
        </Row>
        <Row>
            <Field type="range" name="sort"
                min="1" max="10" step="1" label="Сортування"
                title="Порядоковий номер розташування в переліку" />
        </Row>
        <Row><Field.Status title="Видимість типу" /></Row>
    </Form>
}