import React from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { useOutletContext } from 'react-router-dom'
import Context from '../contexts/Form.js'
import Field from './Form/Field.js'
import Control from './Form/Control.js'
import Table from './Form/Table.js'
import Row from './Form/Row.js'
import Cell from './Form/Cell.js'
import '../assets/styles/components/form.css'

const recurse = (data, name, value, override = true) => {
    if (name.length > 1) {
        if (typeof data[name[0]] === 'undefined') {
            data[name[0]] = {}
        }
        return recurse(data[name[0]], name.slice(1), value, override)
    } else {
        if (typeof value === 'undefined') return data[name[0]]
        if ((name[0] in data) && !override) return
        data[name[0]] = (
            (typeof value == 'string') 
            && !isNaN(value) 
            && !isNaN(parseFloat(value)))
            ? +value : value
    }
}

const Wrapper = props => {

    const context = useOutletContext()

    const actions = { 
        set: (name, value, override) => {
            props.onChange(dataOld => {
                const dataNew = { ...dataOld }
                recurse(dataNew, name.split('.'), value, override)
                return dataNew
            })
        },
        get: name => {
            return recurse(props.data, name.split('.'))
        }
    }

    const handleSubmit = event => {
        event.preventDefault()
        event.stopPropagation()
        props.onSubmit(
            Object.fromEntries(
                new FormData(event.target)
            )
        )
        props.onHide()
    }

    const handleDelete = async () => {
        if (await context.setConfirm('Ви впевненні?')) {
            props.onDelete()
            props.onHide()
        }
    }

    return (
        <Context.Provider value={actions}>
            <Modal show={props.show} onHide={props.onHide} animation={true}
                size={props.size ??
                    (props?.as && (props.as === 'filter') ? 'md' : 'lg')
                } fullscreen={props.fullscreen ?? false} style={{ paddingLeft: '1px' }}
                className="p-0" autoFocus={true} enforceFocus={false}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title id="filterLabel">
                            {props?.title ??
                                (props?.as && (props.as === 'filter')
                                    ? 'Фільтрування даних'
                                    : 'Редагування даних'
                                )
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{props.children}</Modal.Body>
                    <Modal.Footer>
                        {props?.as && (props.as === 'filter')
                            ? <>
                                <Button variant="secondary">Закрити</Button>
                                <Button type="submit">Фільтрувати</Button>
                            </> : <>
                                {props.data?._id && props?.onDelete && (
                                    <Button onClick={handleDelete}
                                        variant="danger" className="me-2">
                                        Видалити
                                    </Button>
                                )}
                                <Button type="submit">Зберегти</Button>
                            </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </Context.Provider>
    )
}

export { Wrapper as default, Field, Control, Table, Row, Cell }