import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import Form, { Field, Row } from '../../components/Form.js'

export default props => {

    const [tag, setTag] = useState({ status: false })
    const context = useOutletContext()

    const handleSubmit = async () => {
        props?.id 
            ? await context.api.panel.put('/мітки/' + props.id, tag)
            : await context.api.panel.post('/мітки', tag)
        props.onSubmit()
    }

    const handleDelete = async () => {
        await context.api.panel.delete('/мітки/' + props.id)
        props.onSubmit()
    }

    useEffect(async () => {
        props?.id && setTag(
            await context.api.panel.get('/мітки/' + props.id)
        )
    }, [])

    return <Form data={tag} show={props.show} onHide={props.onHide}
        onChange={setTag} onSubmit={handleSubmit} onDelete={handleDelete}
        title="Редагування мітки" size="sm">
        <Row>
            <Field.Title maxLength={16} placeholder="Музей"
                title="Від 3 до 16 символів" required />
        </Row>
        <Row>
            <Field.Slug source={tag.title} maxLength={16}
                placeholder="посилання-на-мітку" required />
        </Row>
        <Row><Field.Description placeholder="Опис мітки ..." /></Row>
        <Row><Field.Status label="Видимість мітки" /></Row>
    </Form>
}