import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import config from '../../../config.js'

export default props => {

    const context = useOutletContext()

    const handleImageUpload = async event => {
        if (event.target.files.length !== 1) return
        const formData = new FormData()
        formData.append('image', event.target.files[0])
        props.onChange('image',
            await context.api.image.post('', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ))
    }

    const handleImageRemove = async () => {
        await context.api.image.delete(null, {
            data: { image: props.image }
        })
        props.onChange('image')
    }

    useEffect(() => {
        props.menu.dispatch(
            props.menu.actions.remove(['move', 'remove'])
        )
    }, [])

    return (
        <div className={'main' + (props?.image ? ' image' : '')}
            style={{ backgroundImage: props?.image
                ? `url(${config.image.host + props.image})` : ''}}>
            <h1 contentEditable="true" suppressContentEditableWarning="true"
                onBlur={
                    event => props.onChange('title', event.target.textContent)
                }
                style={{
                    font: '600 calc(16px + 10vw) "Lora", serif',
                    border: props?.image ? 'none' : 'dashed 1px grey; margin: 1em'
                }}>
                {props.title}
            </h1>
            <span className="text-center position-absolute mx-auto"
                style={{ maxWidth: '360px', bottom: '3vh' }}>
                {props?.image
                    ? <Button variant="outline-danger"
                        onClick={handleImageRemove}>Remove image</Button>
                    : <Form.Control type="file" onChange={handleImageUpload}
                        title="Оберіть файл для завантаження" />
                }
            </span>
        </div>
    )
}