import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import Table, { Row, Cell } from '../components/Table.js'
import Editor from './Tag/Editor.js'

export default () => {

    const [id, setID] = useState()
    const [tags, setTags] = useState([])
    const [editor, setEditor] = useState(false)
    const context = useOutletContext()

    const handleLoad = async () => {
        setTags(
            await context.api.panel.get('/мітки')
        )
    }

    useEffect(async () => {
        context.setMeta({
            title: 'Мітки',
            submenu: [{
                title: 'Створити',
                onClick: () => setEditor(true)
            }]
        })
        handleLoad()
    }, [])

    return <>
        <Table columns={['Назва', 'Посилання', 'Опис']}>
            {tags.map(tag => (
                <Row status={tag.status} key={tag._id}
                    onClick={() => {setID(tag._id);setEditor(true)}}>
                    <Cell align="left">{tag.title}</Cell>
                    <Cell align="left">{tag.slug}</Cell>
                    <Cell align="left">{tag?.description}</Cell>
                </Row>
            ))}
        </Table>
        {editor && <Editor id={id} onSubmit={handleLoad}
            show={editor} onHide={() => {setID();setEditor(false)}} />}
    </>
}