import React from 'react'
import { NavLink } from 'react-router-dom'
import config from '../../config.js'
import menu from '../../menu.js'

export default () => {
    return <footer className="text-center mt-5">
        <ul className="nav justify-content-center">
            {menu.map(item => (
                <li className="nav-item" key={item.title}>
                    <NavLink to={encodeURI(item.uri)}
                        className="nav-link small p-2">
                            {item.title}
                    </NavLink>
                </li>
            ))}
        </ul>
        <p className="text-muted small mt-3" title={config.slogan}>
            {config.name} &copy; {config.copyright}
        </p>
    </footer>
}
