import React from 'react'
import Field from './Field.js'

export default props => {

    const name = props.name ?? 'title'
    const minLength = props?.minLength ?? 3
    const maxLength = props?.maxLength ?? 128

    return <Field type="text" name={name} label="Назва"
        pattern="[А-ЯІЇЄҐа-яіїєґ`\-]+" minLength={minLength} maxLength={maxLength}
        title={`від ${minLength} до ${maxLength} символів, дефісу`}
        placeholder="Найменування"
        {...props} />
}
