import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from '../../../components/Form.js'

export default () => {

    const [types, setTypes] = useState([])
    const context = useOutletContext()

    useEffect(async () => {
        setTypes(
            await context.api.panel.get(`/типи`)
        )
    }, [])

    return <Field type="select" name="type" label="Тип публікації">
        {types.map((type, index) => 
            <option value={type._id} key={index}>{type.title}</option>
        )}
    </Field>
}
